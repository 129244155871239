import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../../../store';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'UnitOwnership',
  components: {
    DatePicker,
    party
  },
  data() {
    return {
      unsubscribe: null,
      parent_value_set_id: null,
      showValueSetModal: false,
      showCustModal: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      showCustomerSiteModal: false,
      showVendorSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showPartyModal: false,
      responseMsg: '',
      editMode: false,
      customerSiteType: 'LEASE',
      venderSiteType: 'FMS',
      unitPartyId: 0,
      unitId: 0,
      customerIndex: 0,
      partyType: null,
      unitCustomerData: [
        {
          primary: true,
          customer_type_meaning: null,
          unit_customer_type_vset: null,
          customer_site_name: null,
          sharing_ratio: 0,
          start_date_end_date: [],
          start_date: null,
          end_date: null,
          active: true,
          customer_site_id: 0,
          unit_party_id: 0,
          customer_id: 0,
          customer_name: null,
          vendor_site_name: null,
          vendor_name: null,
          vendor_id: 0,
          vendor_site_id: 0,
          partyId: null,
          property_id: null,
          sf_property_id:null
        }
      ],
      unitCustomerFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'primary',
          label: 'Is Primary',
          stickyColumn: true
        },
        {
          key: 'customer_type_meaning',
          label: 'Customer Type'
        },
        {
          key: 'customer_id'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_site_id'
        },
        {
          key: 'customer_site_name'
        },
        {
          key: 'sharing_ratio'
        },
        {
          key: 'vendor_id'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_site_id'
        },
        {
          key: 'vendor_site_name'
        },
        {
          key: 'property_id'
        },
        {
          key:'sf_property_id'

        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      active: true,
      specIndex: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditFmsUnitOwnershipData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.unitId = store.state.shared.fmsUnitRecord.fms_unit_id;
    this.getFmsUnitOwnershipData();
    this.eventBus.$on('setCustomerData', customerData => {
      this.setCustomerData(customerData);
    });
  },
  methods: {
    startDateIndex(index) {
      this.specIndex = index;
      this.unitCustomerData[index].end_date = null;
    },
    endDateIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.unitCustomerData[this.specIndex].start_date
      );
    },
    getFmsUnitOwnershipData() {
      const payload = {
        fmsUnitId: this.unitId,
        active: this.active
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getFmsUnitOwnershipData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length) {
            const unitCustDtl = response.data.data;
            if (unitCustDtl) {
              this.unitCustomerData = unitCustDtl;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    validateFuncton(ratio, index) {
      var regex = /^\d+(\.\d{0,2})?$/g;
      if (!regex.test(ratio)) {
        alert('Sharing ratio decimal places cannot greater then two');
        this.unitCustomerData[index].sharing_ratio = null;
      }
    },
    addEditFmsUnitOwnershipData() {
      const details = this.unitCustomerData.map(key => {
        return {
          active: key.active,
          customer_id: key.customer_id,
          customer_site_id: key.customer_site_id,
          vendor_id: key.vendor_id,
          vendor_site_id: key.vendor_site_id,
          sharing_ratio: key.sharing_ratio,
          start_date: key.start_date,
          end_date: key.end_date,
          primary: key.primary,
          unit_customer_type_vset: key.unit_customer_type_vset,
          unit_party_id: key.unit_party_id,
          property_id: key.property_id,
          sf_property_id: key.sf_property_id
        };
      });
      const payload = {
        fmsUnitId: this.unitId,
        unitCustomer: {
          fms_unit_party_details: details ? details : null
        }
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/addEditFmsUnitOwnershipData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.editMode = false;
            this.responseMsg = response.data.message;
            const unitCustDtl = response.data.data;
            if (unitCustDtl && unitCustDtl.length > 0) {
              this.unitCustomerData = unitCustDtl;
            }
            this.getFmsUnitOwnershipData();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PARTY_TYPE) {
        this.unitCustomerData[this.customerIndex].unit_customer_type_vset =
          item.value_code;
        this.unitCustomerData[this.customerIndex].customer_type_meaning =
          item.value_meaning;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.customerIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHidePartyModal(flag, index, partyType) {
      this.showPartyModal = flag;
      this.gridIndex = index;
      this.partyType = partyType;
      if (partyType === 'cust') {
        this.setCustCode = setTimeout(() => {
          this.eventBus.$emit('userType', 'CUST');
        }, 0);
      } else {
        this.setCustCode = setTimeout(() => {
          this.eventBus.$emit('userType', 'VEND');
        }, 0);
      }
    },
    selectRadio(specIndex) {
      this.unitCustomerData.forEach((element, index) => {
        return (element.primary = index === specIndex ? true : false);
      });
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    showHideVendorSiteModal(flag) {
      this.showVendorSiteModal = flag;
    },
    getCustomerSiteByPartyId(index) {
      this.gridIndex = index;
      const payload = {
        customerId: this.unitCustomerData[index].customer_id,
        site_type_vset: !this.customerSiteType
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getVendorSiteByPartyId(index) {
      this.gridIndex = index;
      const payload = {
        partyId: this.unitCustomerData[index].partyId,
        lease: {
          site_type_vset: this.venderSiteType
        }
      };
      this.$store
        .dispatch('party/getPartyVenderSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.vendor_sites;
          this.showVendorSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSelectedPartyCustomer(item) {
      if (this.partyType === 'cust') {
        this.unitCustomerData[this.gridIndex].customer_name = item.party_name;
        this.getCustomerByPartyId(item.party_id);
      } else if (this.partyType === 'vend') {
        this.unitCustomerData[this.gridIndex].vendor_name = item.party_name;
        this.unitCustomerData[this.gridIndex].partyId = item.party_id;
        this.getPartyVendorDetails(item.party_id);
      }
      this.showPartyModal = false;
    },
    getSelectedVendorName() {},
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.unitCustomerData[this.gridIndex].customer_id =
              response.data.data.customer_id;
          }
        });
    },
    getPartyVendorDetails(partyId) {
      this.$store
        .dispatch('party/getPartyVenderSiteList', partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.unitCustomerData[this.gridIndex].vendor_id =
              response.data.data.vendor_id;
          }
        });
    },
    selectedCustomerSite(item) {
      this.unitCustomerData[this.gridIndex].customer_site_name = item.site_name;
      this.unitCustomerData[this.gridIndex].customer_site_id =
        item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    selectedVendorSite(item) {
      this.unitCustomerData[this.gridIndex].vendor_site_name = item.site_name;
      this.unitCustomerData[this.gridIndex].vendor_site_id =
        item.vendor_site_id;
      this.showVendorSiteModal = false;
    },
    hideCustomerModal(flag, index) {
      this.showCustModal = flag;
      this.customerIndex = index;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    setCustomerData(item) {
      let unitPartyRow = this.unitCustomerData[this.customerIndex];
      unitPartyRow.customer_id = item.customerId;
      unitPartyRow.customer_name = this.$store.state.party.partyName;
      unitPartyRow.customer_site_id = item.customerData.customer_site_id;
      unitPartyRow.customer_site_name = item.customerData.site_name;
      this.showCustModal = false;
    },
    validateSharingRatio(row) {
      let sum = 0;
      this.unitCustomerData.forEach(item => {
        if (item.active) {
          sum += parseFloat(item.sharing_ratio);
          if (sum > 100) {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = 'Sharing Ratio cannot be greater than 100!';
            sum = 0;
            row.sharing_ratio = 0;
          }
        } else {
          if (row.sharing_ratio > 100) {
            row.sharing_ratio = 0;
          }
        }
      });
    },
    addNewRow() {
      if (this.editMode) {
        this.unitCustomerData.push({
          customer_type_meaning: null,
          unit_customer_type_vset: null,
          customer_site_name: null,
          start_date_end_date: [],
          start_date: null,
          sharing_ratio: 0,
          end_date: null,
          active: true,
          customer_site_id: null,
          unit_party_id: 0,
          primary: false,
          customer_id: null,
          customer_name: null,
          vendor_site_name: null,
          vendor_name: null,
          vendor_id: null,
          vendor_site_id: null,
          partyId: null,
          property_id: null,
          sf_property_id:null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.unitCustomerData.splice(index, 1);
      }
    },
    getVendorSites(index) {
      if (this.unitCustomerData[index].partyId) {
        this.getVendorSiteByPartyId(index);
      } else {
        this.getVendorSiteByVendorId(index);
      }
    },
    getVendorSiteByVendorId(index) {
      this.gridIndex = index;
      this.$store
        .dispatch(
          'leaseSchedule/getVendorSiteByVendorId',
          this.unitCustomerData[index].vendor_id
        )
        .then(response => {
          this.gridDataSite = response.data.data.vendor_sites;
          this.showVendorSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'FMS_PARTY_TYPE') {
        this.unitCustomerData[index].unit_customer_type_vset = null;
        this.unitCustomerData[index].customer_type_meaning = null;
      } else if (vsetCode === 'CUSTOMER') {
        this.unitCustomerData[index].customer_name = null;
        this.unitCustomerData[index].customer_id = null;
        this.unitCustomerData[index].customer_site_name = null;
        this.unitCustomerData[index].customer_site_id = null;
      } else if (vsetCode === 'CUSTOMER_SITE') {
        this.unitCustomerData[index].customer_site_name = null;
        this.unitCustomerData[index].customer_site_id = null;
      } else if (vsetCode === 'VENDOR') {
        this.unitCustomerData[index].vendor_name = null;
        this.unitCustomerData[index].vendor_id = null;
        this.unitCustomerData[index].vendor_site_name = null;
        this.unitCustomerData[index].vendor_site_id = null;
      } else if (vsetCode === 'VENDOR_SITE') {
        this.unitCustomerData[index].vendor_site_name = null;
        this.unitCustomerData[index].vendor_site_id = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  destroyed() {
    this.eventBus.$off('setCustomerData');
  }
};
