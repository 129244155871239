import appStrings from '@/app/utility/string.utility';
import store from '../../../../../../../store';
export default {
  name: 'UnitOrifinalArea',
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      intervalClass: null,
      selectedInterval: null,
      unitId: null,
      unitAreaId: 0,
      areaType: null,
      superArea: null,
      superAreaUnit: {
        value: null,
        text: null
      },
      capetArea: null,
      capetAreaUnit: {
        value: null,
        text: null
      },
      landArea: null,
      landAreaUnit: {
        value: null,
        text: null
      },
      basementArea: null,
      basementAreaUnit: {
        value: null,
        text: null
      },
      terraceArea: null,
      terraceAreaUnit: {
        value: null,
        text: null
      },
      billableArea: null,
      enviroFmsBillingArea: null,
      billableAreaUnit: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    this.getUomClass();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditFmsUnitArea();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.unitId = store.state.shared.fmsUnitRecord.fms_unit_id;
    this.getFmsUnitOrgArea();
  },
  methods: {
    getFmsUnitOrgArea() {
      const payload = {
        fmsUnitId: this.unitId
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getFmsUnitOriginalArea', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          this.unitId = results.fms_unit_id;
          this.unitAreaId = results.fms_unit_area_id;
          this.superArea = results.super_area;
          this.superAreaUnit = {
            value: results.super_area_uom,
            text: results.super_area_vset_meaning
          };
          this.terraceArea = results.terrace_area;
          this.terraceAreaUnit = {
            value: results.terrace_area_uom,
            text: results.terrace_area_vset_meaning
          };
          this.landArea = results.land_area;
          this.landAreaUnit = {
            value: results.land_area_uom,
            text: results.land_area_vset_meaning
          };
          this.basementArea = results.basement_area;
          this.basementAreaUnit = {
            value: results.basement_area_uom,
            text: results.basement_area_vset_meaning
          };
          this.capetArea = results.carpet_area;
          this.capetAreaUnit = {
            value: results.carpet_area_uom,
            text: results.carpet_area_vset_meaning
          };
          this.billableArea = results.billable_area;
          this.enviroFmsBillingArea = results.fms_billing_area_enviro;
          this.billableAreaUnit = {
            value: results.billable_area_uom_vset,
            text: results.billable_area_uom_vset_meaning
          };
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditFmsUnitArea() {
      const payload = {
        fmsUnitId: this.unitId,
        unitAreaDetail: {
          basement_area: this.basementArea,
          basement_area_uom_vset: this.basementAreaUnit.value,
          land_area: this.landArea,
          land_area_uom_vset: this.landAreaUnit.value,
          fms_unit_area_id: this.unitAreaId,
          carpet_area: this.capetArea,
          carpet_area_uom_vset: this.capetAreaUnit.value,
          super_area: this.superArea,
          super_area_uom_vset: this.superAreaUnit.value,
          terrace_area: this.terraceArea,
          terrace_area_uom_vset: this.terraceAreaUnit.value,
          billable_area: this.billableArea,
          billable_area_uom_vset: this.billableAreaUnit.value
        }
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/addEditFmsUnitOriginalArea', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            const results = response.data.data;
            this.unitAreaId = results.fms_unit_area_id;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getUomClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.superAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.capetAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.landAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.basementAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.terraceAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.billableAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.parent_value_set_id = this.intervalClass[0].detail_id;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'UOM') {
        this.superAreaUnit = this.defaultValue;
        this.capetAreaUnit = this.defaultValue;
        this.landAreaUnit = this.defaultValue;
        this.basementAreaUnit = this.defaultValue;
        this.terraceAreaUnit = this.defaultValue;
        this.billableAreaUnit = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
