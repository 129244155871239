import DatePicker from 'vue2-datepicker';
import FmsMasterProj from '../../fmsMasterProject';
import FmsSector from '../../fmsSector';
import FmsPocket from '../../fmsPocket';
import FmsProject from '../../fmsProject';
import FmsTower from '../../fmsTower';
import FmsFloor from '../../fmsFloor';
import { format } from 'date-fns';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../../store';
export default {
  name: 'FmsUnitDetails',
  components: {
    DatePicker,
    FmsMasterProj,
    FmsSector,
    FmsProject,
    FmsPocket,
    FmsTower,
    FmsFloor
  },
  data() {
    return {
      unsubscribe: null,
      parent_value_set_id: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showFmsUnitDetails: false,
      showFmsComponent: false,
      showValueSetModal: false,
      editMode: false,
      tab: null,
      fmsMasterProj: {
        value: null,
        text: null
      },
      fmsSector: {
        value: null,
        text: null
      },
      fmsPocket: {
        value: null,
        text: null
      },
      fmsProj: {
        value: null,
        text: null
      },
      fmsTower: {
        value: null,
        text: null
      },
      fmsFloor: {
        value: null,
        text: null
      },
      crmFloorId: null,
      crmUnit: {
        value: null,
        text: null
      },
      fmsUnitName: null,
      fmsUnitDisplay: null,
      fmsUnitId: 0,
      offerPossessionDate: null,
      physicalPossessionDate: null,
      isSubunitLeaseable: false,
      isOutrightUnit: false,
      isActive: true,
      possessionDate: null,
      registryDate: null,
      agreementDate: null,
      billCommenceDate: null,
      unitCategory: {
        value: null,
        text: null,
        id: null
      },
      unitSubcategory: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      fmsUnitDisplayName: null,
      isPossessionable:false,
      leasable: {
        value : null,
        text : null
      },
      isShownOnPortal:false
    };
  },
  mounted() {
    this.fmsUnitRowDtl = store.state.shared.fmsUnitRecord;
    if (this.fmsUnitRowDtl) {
      this.fillFmsUnitDetails(this.fmsUnitRowDtl);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditFmsUnit();
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.fmsUnitId });
        }
      }
    });
  },
  methods: {
    addEditFmsUnit() {
      const payload = {
        agreement_date: this.agreementDate
          ? format(new Date(this.agreementDate), 'dd-MMM-yyyy')
          : null,
        bill_commence_date: this.billCommenceDate
          ? format(new Date(this.billCommenceDate), 'dd-MMM-yyyy')
          : null,
        crm_unit_id: this.crmUnit.value,
        fms_floor_id: this.fmsFloor.value,
        fms_pocket_id: this.fmsPocket.value,
        fms_prj_id: this.fmsProj.value,
        fms_sector_id: this.fmsSector.value,
        is_leaseable_unit: this.isSubunitLeaseable,
        is_outright_unit: this.isOutrightUnit,
        is_active: this.isActive,
        fms_unit_id: this.fmsUnitId,
        offer_possession_date: this.offerPossessionDate
          ? format(new Date(this.offerPossessionDate), 'dd-MMM-yyyy')
          : null,
        phy_possession_date: this.physicalPossessionDate
          ? format(new Date(this.physicalPossessionDate), 'dd-MMM-yyyy')
          : null,
        possession_date: this.possessionDate
          ? format(new Date(this.possessionDate), 'dd-MMM-yyyy')
          : null,
        registry_date: this.registryDate
          ? format(new Date(this.registryDate), 'dd-MMM-yyyy')
          : null,
        unit_cat_vset: this.unitCategory.value,
        unit_display_name: this.fmsUnitDisplay,
        unit_name: this.fmsUnitName,
        unit_sub_cat_vset: this.unitSubcategory.value,
        is_possessionable_unit :this.isPossessionable,
        leasable_reason_vset:this.leasable.value,
        is_shown_on_portal :this.isShownOnPortal

        
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/addEditFmsUnit', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.fmsUnitId = response.data.data.fms_unit_id;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'FmsMasterProj') {
        return (this.childName = 'FMS Master Proj');
      } else if (tab === 'FmsSector') {
        return (this.childName = 'FMS Sector');
      } else if (tab === 'FmsPocket') {
        return (this.childName = 'FMS Pocket');
      } else if (tab === 'FmsProject') {
        return (this.childName = 'FMS Project');
      } else if (tab === 'FmsTower') {
        return (this.childName = 'FMS Tower');
      } else if (tab === 'FmsFloor') {
        return (this.childName = 'FMS Floor');
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UNIT_CAT') {
        this.unitCategory = {
          value: item.value_code,
          text: item.value_meaning,
          id: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_SUB_CAT)  {
        this.unitSubcategory = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_UNIT) {
        this.crmUnit = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
      else if (this.vsetCode ===  appStrings.VALUESETTYPE.LEASEABLE_REASON_VSET){
        this.leasable = {
          value: item.value_code,
          text: item.value_meaning
        }
       }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_UNIT) {
        this.parent_value_set_id = this.crmFloorId;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_SUB_CAT) {
        this.parent_value_set_id = this.unitCategory.id;
      } 
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    fillFmsUnitDetails(item) {
     this.fmsMasterProj = {
        value: item.fms_master_project_id,
        text: item.fms_master_project_name
      };
      this.fmsSector = {
        value: item.fms_sector_id,
        text: item.fms_sector_name
      };
      this.fmsPocket = {
        value: item.fms_pocket_id,
        text: item.fms_pocket_name
      };
      this.fmsProj = {
        value: item.fms_project_id,
        text: item.fms_project_name
      };
      this.fmsTower = {
        value: item.fms_tower_id,
        text: item.fms_tower_name
      };
      this.fmsFloor = {
        value: item.fms_floor_id,
        text: item.floor_name
      };
      this.crmUnit = {
        value: item.crm_unit_id,
        text: item.crm_unit_name
      };
      this.fmsUnitName = item.unit_name;
      this.fmsUnitDisplay = item.unit_display_name;
      this.fmsUnitId = item.fms_unit_id;
      this.possessionDate = item.possession_date;
      this.offerPossessionDate = item.offer_possession_date;
      this.physicalPossessionDate = item.phy_possession_date;
      this.registryDate = item.registry_date;
      this.agreementDate = item.agreement_date;
      this.isSubunitLeaseable = item.is_leaseable_unit;
      this.isOutrightUnit = item.is_outright_unit;
      this.isActive = item.is_active;
      this.billCommenceDate = item.bill_commence_date;
      this.unitCategory = {
        value: item.unit_category_vset,
        text: item.unit_category_meaning,
        id: null
      };
      this.unitSubcategory = {
        value: item.unit_sub_category_vset,
        text: item.unit_sub_category_meaning
      };
      this.fmsUnitDisplayName = item.fms_unit_display_name;
      this.isPossessionable =item.is_possessionable_unit;
      this.leasable.text = item.leasable_reason_vset_meaning;
      this.leasable.value = item.leasable_reason_vset;
      this.isShownOnPortal = item.is_shown_on_portal;
    },
    hideFmsComponent(flag, name) {
      this.showFmsComponent = flag;
      this.tab = name;
      this.setChildName(this.tab);
    },
    selectedFmsProj(item) {
      this.showFmsComponent = false;
      this.fmsProj = {
        value: item.fms_prj_id,
        text: item.fms_prj_name
      };
      this.fmsMasterProj = {
        value: item.fms_master_prj_id,
        text: item.fms_master_prj_name
      };
      this.fmsPocket = {
        value: item.fms_pocket_id,
        text: item.fms_pocket_name
      };
      this.fmsSector = {
        value: item.fms_sector_id,
        text: item.fms_sector_name
      };
    },
    selectedFmsTower(item) {
      this.showFmsComponent = false;
      this.fmsTower = {
        value: item.fms_tower_id,
        text: item.fms_tower_name
      };
    },
    selectedFmsFloor(item) {
      this.showFmsComponent = false;
      this.fmsFloor = {
        value: item.fms_floor_id,
        text: item.floor_name
      };
      this.crmFloorId = item.crm_floor_id;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.fmsProj.value) {
        this.fmsProj = this.defaultValue;
        this.fmsTower = this.defaultValue;
        this.fmsFloor = this.defaultValue;
        this.fmsMasterProj = this.defaultValue;
        this.fmsSector = this.defaultValue;
        this.fmsPocket = this.defaultValue;
      } else if (vsetCode === this.fmsTower.value) {
        this.fmsTower = this.defaultValue;
        this.fmsFloor = this.defaultValue;
      } else if (vsetCode === this.fmsFloor.value) {
        this.fmsFloor = this.defaultValue;
      } else if (vsetCode === this.crmUnit.value) {
        this.crmUnit = this.defaultValue;
      } else if (vsetCode === this.unitCategory.value) {
        this.unitCategory = {
          value: null,
          text: null,
          id: null
        };
        this.unitSubcategory = this.defaultValue;
        this.parent_value_set_id = null;
      } else if (vsetCode === this.unitSubcategory.value) {
        this.unitSubcategory = this.defaultValue;
      }
      else if (vsetCode === this.leasable.value){
        this.leasable = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
