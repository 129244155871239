import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import store from '../../../../../../../store';
export default {
  name: 'SubUnitOriginalArea',
  components: { DatePicker },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      intervalClass: null,
      selectedInterval: null,
      subUnitId: null,
      areaType: null,
      areaIndex: 0,
      fmsUnitName: null,
      fmsUnitUom: null,
      fmsUnitBillableArea: null,
      fmsUnitAreaDetails: [],
      fmsUnitAreaFields: [
        {
          key: 'sub_unit_name',
          label: 'Sub Unit'
        },
        {
          key: 'sub_unit_billable_area',
          label: 'Billable Area'
        },
        {
          key: 'uom_meaning',
          label: 'Billable Area UOM'
        }
      ],
      subUnitRevisedAreaData: [
        {
          terrace_area: null,
          active: false,
          land_area_mening: null,
          terrace_area_meaning: null,
          basement_area_mening: null,
          terrace_area_uom: null,
          carpet_area_uom: null,
          land_area_uom: null,
          basement_area_uom: null,
          super_area_meaning: null,
          carpet_area_meaning: null,
          billable_area: null,
          fms_billing_area_enviro: null,
          start_date_end_date: [],
          end_date: null,
          start_date: null,
          super_area_uom: null,
          billable_area_uom: null,
          land_area: null,
          basement_area: null,
          super_area: null,
          carpet_area: null,
          fms_unit_area_id: 0,
          billable_area_meaning: null
        }
      ],
      subUnitRevisedAreaFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'super_area'
        },
        {
          key: 'super_area_meaning',
          label: 'Super Area UOM'
        },
        {
          key: 'carpet_area'
        },
        {
          key: 'carpet_area_meaning',
          label: 'Carpet Area UOM'
        },
        {
          key: 'land_area'
        },
        {
          key: 'land_area_mening',
          label: 'Land Area UOM'
        },
        {
          key: 'basement_area'
        },
        {
          key: 'basement_area_mening',
          label: 'Basement Area UOM'
        },
        {
          key: 'terrace_area'
        },
        {
          key: 'terrace_area_meaning',
          label: 'Terrace Area UOM'
        },
        {
          key: 'billable_area'
        },
        {
          key: 'fms_billing_area_enviro',
          label: 'Enviro FMS Billing Area'
        },
        {
          key: 'billable_area_meaning',
          label: 'Billable Area UOM'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      specIndex: null
    };
  },
  mounted() {
    this.subUnitId = store.state.shared.fmsSubunitRecord.fms_sub_unit_id;
    this.getFmsSubUnitAreaDetails();
    this.getFmsSubUnitRevisedArea();
    this.getUomClass();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditRevisedSubUnitArea();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getFmsSubUnitRevisedArea() {
      const payload = {
        fmsSubUnitId: this.subUnitId
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getFmsSubUnitRevisedArea', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          if (results && results.length > 0) {
            this.subUnitRevisedAreaData = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    startDateIndex(index) {
      this.specIndex = index;
      this.subUnitRevisedAreaData[index].end_date = null;
    },
    endDateIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.subUnitRevisedAreaData[this.specIndex].start_date
      );
    },
    addEditRevisedSubUnitArea() {
      const details = this.subUnitRevisedAreaData.map(item => {
        return {
          active: item.active,
          fms_unit_area_id: item.fms_unit_area_id,
          super_area: item.super_area,
          super_area_uom_vset: item.super_area_uom,
          carpet_area: item.carpet_area,
          carpet_area_uom_vset: item.carpet_area_uom,
          land_area: item.land_area,
          land_area_uom_vset: item.land_area_uom,
          basement_area: item.basement_area,
          basement_area_uom_vset: item.basement_area_uom,
          terrace_area: item.terrace_area,
          terrace_area_uom_vset: item.terrace_area_uom,
          billable_area: item.billable_area,
          billable_area_uom_vset: item.billable_area_uom,
          start_date: item.start_date,
          end_date: item.end_date
        };
      });
      const payload = {
        fmsSubUnitId: this.subUnitId,
        fms_sub_unit_area_details: {
          fms_sub_unit_area_details: details ? details : null
        }
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/addEditFmsSubUnitRevisedArea', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            const results = response.data.data;
            this.subUnitRevisedAreaData = results;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getFmsSubUnitAreaDetails() {
      this.$store
        .dispatch(
          'unitInventory/getFmsSubUnitAreaDetails',
          store.state.shared.fmsSubunitRecord.fms_unit_id
        )
        .then(resp => {
          if (resp.status === 200) {
            const result = resp.data.data;
            this.fmsUnitName = result.unit_name;
            this.fmsUnitUom = result.uom_meaning;
            this.fmsUnitBillableArea = result.unit_billable_area;
            this.fmsUnitAreaDetails = result.fms_sub_unit_mpng_hdr_details;
          }
        });
    },

    addNewRow() {
      let sum = 0;
      this.subUnitRevisedAreaData.forEach(element => {
        sum += element.super_area;
      });
      if (this.fmsUnitBillableArea > sum) {
        sum = this.fmsUnitBillableArea - sum;
        this.subUnitRevisedAreaData.push({
          terrace_area: null,
          active: false,
          land_area_mening: null,
          terrace_area_meaning: null,
          basement_area_mening: null,
          terrace_area_uom: null,
          carpet_area_uom: null,
          land_area_uom: null,
          basement_area_uom: null,
          super_area_meaning: null,
          carpet_area_meaning: null,
          billable_area: sum,
          start_date_end_date: [],
          end_date: null,
          start_date: null,
          super_area_uom: null,
          billable_area_uom: null,
          land_area: null,
          basement_area: null,
          super_area: sum,
          carpet_area: null,
          fms_unit_area_id: 0,
          billable_area_meaning: null
        });
      } else {
        alert('FMS Unit Billable Area Utilized');
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.subUnitRevisedAreaData.splice(index, 1);
      }
    },
    getUomClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.subUnitRevisedAreaData[this.areaIndex].super_area_uom =
          item.value_code;
        this.subUnitRevisedAreaData[this.areaIndex].super_area_meaning =
          item.value_meaning;
        this.subUnitRevisedAreaData[this.areaIndex].carpet_area_uom =
          item.value_code;
        this.subUnitRevisedAreaData[this.areaIndex].carpet_area_meaning =
          item.value_meaning;
        this.subUnitRevisedAreaData[this.areaIndex].land_area_uom =
          item.value_code;
        this.subUnitRevisedAreaData[this.areaIndex].land_area_mening =
          item.value_meaning;
        this.subUnitRevisedAreaData[this.areaIndex].basement_area_uom =
          item.value_code;
        this.subUnitRevisedAreaData[this.areaIndex].basement_area_mening =
          item.value_meaning;
        this.subUnitRevisedAreaData[this.areaIndex].billable_area_uom =
          item.value_code;
        this.subUnitRevisedAreaData[this.areaIndex].billable_area_meaning =
          item.value_meaning;
        this.subUnitRevisedAreaData[this.areaIndex].terrace_area_uom =
          item.value_code;
        this.subUnitRevisedAreaData[this.areaIndex].terrace_area_meaning =
          item.value_meaning;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.areaIndex = index;
      this.parent_value_set_id = this.intervalClass[0].detail_id;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'UOM') {
        this.subUnitRevisedAreaData[index].super_area_uom = null;
        this.subUnitRevisedAreaData[index].super_area_meaning = null;
        this.subUnitRevisedAreaData[index].carpet_area_uom = null;
        this.subUnitRevisedAreaData[index].carpet_area_meaning = null;
        this.subUnitRevisedAreaData[index].land_area_uom = null;
        this.subUnitRevisedAreaData[index].land_area_mening = null;
        this.subUnitRevisedAreaData[index].basement_area_uom = null;
        this.subUnitRevisedAreaData[index].basement_area_mening = null;
        this.subUnitRevisedAreaData[index].billable_area_uom = null;
        this.subUnitRevisedAreaData[index].billable_area_meaning = null;
        this.subUnitRevisedAreaData[index].terrace_area_uom = null;
        this.subUnitRevisedAreaData[index].terrace_area_meaning = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
