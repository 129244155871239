import originalUnitArea from './subUnitOriginalArea';
import fmsSubUnitSpecification from './subUnitSpecification';
import fmsSubUnitCustomer from './subUnitCustomer';
import DatePicker from 'vue2-datepicker';
import FmsProject from '../../fmsProject';
import FmsTower from '../../fmsTower';
import FmsFloor from '../../fmsFloor';
import FmsUnit from '../../fmsUnit';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../../store';
export default {
  name: 'SubUnitInfo',
  components: {
    originalUnitArea,
    fmsSubUnitSpecification,
    fmsSubUnitCustomer,
    DatePicker,
    FmsProject,
    FmsTower,
    FmsFloor,
    FmsUnit
  },
  data() {
    return {
      loader: false,
      actionName: null,
      activeTab: 'SubUnitDetails',
      revisedArea: false,

      unsubscribe: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showFmsSubUnitDetails: false,
      showFmsComponent: false,
      editMode: false,
      tab: null,
      fmsProj: {
        value: null,
        text: null
      },
      fmsTower: {
        value: null,
        text: null
      },
      fmsFloor: {
        value: null,
        text: null
      },
      fmsUnit: {
        value: null,
        text: null
      },
      flag: null,
      fmsSubUnitName: null,
      fmsSubUnitId: 0,
      start_date_end_date: null,
      start_date: null,
      end_date: null,
      active: true,
      defaultFlag: false,
      dummy: false,
      freeze: false
    };
  },
  created() {
    this.fmsSubUnitRowDtl = store.state.shared.fmsSubunitRecord;
  },
  mounted() {
    if (this.fmsSubUnitRowDtl) {
      this.fillFmsSubUnitDetails(this.fmsSubUnitRowDtl);
    } else if (store.state.shared.subUnitd) {
      this.getSubUnitDetailsById();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (
          actionName === 'save' &&
          this.editMode &&
          this.activeTab === 'SubUnitDetails'
        ) {
          this.addEditFmsSubUnit();
        }
        if (actionName === 'upload' && this.activeTab === 'SubUnitDetails') {
          this.eventBus.$emit('commonUpload', { id: this.fmsSubUnitId });
        }
      }
    });
  },
  methods: {
    selectedTab(tabValue) {
      this.activeTab = tabValue;
      if (this.activeTab === 'RevisedArea') {
        this.revisedArea = true;
      } else {
        this.revisedArea = false;
      }
    },
    closeTabs() {
      this.$emit('closeUnitInfo');
      this.$emit('closeCreatedSubunit');
    },
    buttonAccessPermissions(actionName) {
      this.actionName = actionName;
    },
    addEditFmsSubUnit() {
      const payload = {
        active: this.active,
        default_flag: this.defaultFlag,
        dummy: this.dummy,
        fms_sub_unit_id: this.fmsSubUnitId,
        fms_sub_unit_name: this.fmsSubUnitName,
        fms_unit_id: this.fmsUnit.value,
        freeze_flag: this.freeze,
        start_date: this.start_date,
        end_date: this.end_date
        // start_date: commonHelper.formattedDate(this.start_date_end_date[0]),
        // end_date: commonHelper.formattedDate(this.start_date_end_date[1])
      };
      this.loader = false;
      this.$store
        .dispatch('unitInventory/addEditFmsSubUnit', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.editMode = false;
            this.responseMsg = response.data.message;
            const subunitResult = response.data.data;
            this.fmsSubUnitId = subunitResult.fms_sub_unit_id;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getSubUnitDetailsById() {
      const subUnitd = store.state.shared.subUnitd;
      this.loader = false;
      this.$store
        .dispatch('unitInventory/getSubUnitDetailsById', subUnitd)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.fillFmsSubUnitDetails(response.data.data);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'FmsTower') {
        return (this.childName = 'FMS Tower');
      } else if (tab === 'FmsFloor') {
        return (this.childName = 'FMS Floor');
      } else if (tab === 'FmsUnit') {
        return (this.childName = 'FMS Unit');
      } else if (tab === 'FmsProject') {
        return (this.childName = 'FMS Project');
      }
    },
    hideFmsComponent(flag, name) {
      this.showFmsComponent = flag;
      this.tab = name;
      this.setChildName(this.tab);
    },
    fillFmsSubUnitDetails(item) {
      this.fmsProj = {
        value: item.fms_project_id,
        text: item.fms_project_name
      };
      this.fmsTower = {
        value: item.fms_tower_id,
        text: item.fms_tower_name
      };
      this.fmsFloor = {
        value: item.fms_floor_id,
        text: item.fms_floor_name
      };
      this.fmsUnit = {
        value: item.fms_unit_id,
        text: item.fms_unit_name
      };
      this.fmsSubUnitName = item.fms_sub_unit_name;
      this.fmsSubUnitId = item.fms_sub_unit_id;
      this.start_date = item.start_date;
      this.end_date = item.end_date;
      this.active = item.active;
      this.defaultFlag = item.default_flag;
      this.dummy = item.dummy;
      this.freeze = item.freeze_flag;
    },
    selectedFmsUnit(item) {
      this.showFmsComponent = false;
      this.fmsUnit = {
        value: item.fms_unit_id,
        text: item.unit_name
      };
      this.fmsProj = {
        value: item.fms_project_id,
        text: item.fms_project_name
      };
      this.fmsTower = {
        value: item.fms_tower_id,
        text: item.fms_tower_name
      };
      this.fmsFloor = {
        value: item.fms_floor_id,
        text: item.floor_name
      };
    }
  },
  beforeDestroy() {
    this.$store.dispatch('shared/setDataForFmsSubunit', null);
    this.unsubscribe();
  }
};
