import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../../../store';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'SubUnitSpecification',
  components: { DatePicker },
  data() {
    return {
      unsubscribe: null,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      specIndex: 0,
      subUnitSpecificaitonData: [
        {
          fms_sub_unit_spec_id: 0,
          unit_cat_vset_meaning: null,
          unit_sub_type_vset: null,
          unit_sub_type_meaning: null,
          unitCategoryId: null,
          unit_type_meaning: null,
          unit_type_vset: null,
          end_date: null,
          start_date: null,
          fms_sub_unit_id: 0,
          unit_cat_vset: null,
          active: true
        }
      ],
      subUnitSpecificaitonFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'unit_type_meaning',
          label: 'SubUnit Type'
        },
        {
          key: 'unit_cat_vset_meaning',
          label: 'SubUnit Category'
        },
        {
          key: 'unit_sub_type_meaning',
          label: 'SubUnit Sub Category'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ]
    };
  },
  mounted() {
    this.fmsSubUnitRowDtl = store.state.shared.fmsSubunitRecord;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditFmsSubUnitSpecification();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    if (this.fmsSubUnitRowDtl) {
      this.getFmsSubUnitSpecification(this.fmsSubUnitRowDtl.fms_sub_unit_id);
    }
  },
  methods: {
    getFmsSubUnitSpecification(subUnitId) {
      const payload = {
        fmsSubUnitId: subUnitId
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getFmsSubUnitSpecification', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const subUnitSpecDtl = response.data.data;
            if (subUnitSpecDtl && subUnitSpecDtl.length > 0) {
              this.subUnitSpecificaitonData = subUnitSpecDtl;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditFmsSubUnitSpecification() {
      const details = this.subUnitSpecificaitonData.map(key => {
        return {
          start_date: key.start_date,
          end_date: key.end_date,
          fms_sub_unit_spec_id: key.fms_sub_unit_spec_id,
          unit_cat_vset: key.unit_cat_vset,
          unit_sub_type_vset: key.unit_sub_type_vset,
          unit_type_vset: key.unit_type_vset,
          active: key.active
        };
      });
      const payload = {
        fmsSubUnitId: this.fmsSubUnitRowDtl.fms_sub_unit_id,
        subUnitSpec: {
          sub_unit_specifications: details ? details : null
        }
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/addEditFmsSubUnitSpecification', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.editMode = false;
            this.responseMsg = response.data.message;
            this.subUnitSpecificaitonData = response.data.data;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_CAT) {
        this.subUnitSpecificaitonData[this.specIndex].unit_cat_vset =
          item.value_code;
        this.subUnitSpecificaitonData[this.specIndex].unit_cat_vset_meaning =
          item.value_meaning;
        this.subUnitSpecificaitonData[this.specIndex].unitCategoryId =
          item.value_set_dtl_id;
        this.subUnitSpecificaitonData[this.specIndex].unit_sub_type_vset = null;
        this.subUnitSpecificaitonData[
          this.specIndex
        ].unit_sub_type_meaning = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_SUB_CAT) {
        this.subUnitSpecificaitonData[this.specIndex].unit_sub_type_vset =
          item.value_code;
        this.subUnitSpecificaitonData[this.specIndex].unit_sub_type_meaning =
          item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_TYPE) {
        this.subUnitSpecificaitonData[this.specIndex].unit_type_vset =
          item.value_code;
        this.subUnitSpecificaitonData[this.specIndex].unit_type_meaning =
          item.value_meaning;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_SUB_CAT) {
        this.parent_value_set_id = this.subUnitSpecificaitonData[
          this.specIndex
        ].unitCategoryId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    startDateIndex(index) {
      this.specIndex = index;
      this.subUnitSpecificaitonData[index].end_date = null;
    },
    endDateIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.subUnitSpecificaitonData[this.specIndex].start_date
      );
    },
    addNewRow() {
      if (this.editMode) {
        this.parent_value_set_id = null;
        this.subUnitSpecificaitonData.push({
          fms_sub_unit_spec_id: 0,
          unit_cat_vset_meaning: null,
          unit_sub_type_vset: null,
          unit_sub_type_meaning: null,
          unitCategoryId: null,
          unit_type_meaning: null,
          unit_type_vset: null,
          start_date_end_date: null,
          end_date: null,
          start_date: null,
          fms_sub_unit_id: 0,
          unit_cat_vset: null,
          active: true
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.subUnitSpecificaitonData.splice(index, 1);
      }
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'UNIT_CAT') {
        this.subUnitSpecificaitonData[index].unit_cat_vset = null;
        this.subUnitSpecificaitonData[index].unit_cat_vset_meaning = null;
        this.subUnitSpecificaitonData[index].unitCategoryId = null;
        this.subUnitSpecificaitonData[index].unit_sub_type_vset = null;
        this.subUnitSpecificaitonData[index].unit_sub_type_meaning = null;
      } else if (vsetCode === 'UNIT_SUB_CAT') {
        this.subUnitSpecificaitonData[index].unit_sub_type_vset = null;
        this.subUnitSpecificaitonData[index].unit_sub_type_meaning = null;
      } else if (vsetCode === 'PRJ_TYPE') {
        this.subUnitSpecificaitonData[index].unit_type_vset = null;
        this.subUnitSpecificaitonData[index].unit_type_meaning = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
