import appStrings from '@/app/utility/string.utility';
import party from '@/app/components/generalLedger/party';
import DatePicker from 'vue2-datepicker';
import store from '../../../../../../../store';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'SubUnitCustomer',
  components: { party, DatePicker },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      setCustCode: null,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      showValueSetModal: false,
      showCustomerSiteModal: false,
      showCustModal: false,
      customerIndex: 0,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showPartyModal: false,
      gridIndex: null,
      subUnitCustomerData: [
        {
          customer_type_meaning: null,
          customer_type_code: null,
          customer_site_name: null,
          start_date: null,
          end_date: null,
          active: true,
          customer_site_id: 0,
          allotment_number: null,
          sub_unit_party_id: 0,
          is_primary_party: true,
          address_id: 0,
          bill_to_address: null,
          customer_id: 0,
          party_name: null
        }
      ],
      subUnitCustomerFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'is_primary_party'
        },
        {
          key: 'customer_type_meaning',
          label: 'Customer Type'
        },
        {
          key: 'party_name',
          label: 'Customer Name'
        },
        {
          key: 'customer_site_name'
        },
        {
          key: 'bill_to_address',
          label: 'Customer Address'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ]
    };
  },
  mounted() {
    this.fmsSubUnitRowDtl = store.state.shared.fmsSubunitRecord;
    if (this.fmsSubUnitRowDtl) {
      this.getFmsSubUnitCustomerList(this.fmsSubUnitRowDtl.fms_sub_unit_id);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditFmsSubUnitCustomer();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.eventBus.$on('setCustomerData', customerData => {
      this.setCustomerData(customerData);
    });
  },
  methods: {
    getFmsSubUnitCustomerList(subUnitId) {
      const payload = {
        fmsSubUnitId: subUnitId
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getFmsSubUnitCustomerList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const subUnitCustDtl = response.data.data;
            if (subUnitCustDtl && subUnitCustDtl.length > 0) {
              this.subUnitCustomerData = subUnitCustDtl;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditFmsSubUnitCustomer() {
      const details = this.subUnitCustomerData.map(key => {
        return {
          active: key.active,
          allotment_number: key.allotment_number,
          customer_id: key.customer_id,
          customer_site_id: key.customer_site_id,
          customer_type_code: key.customer_type_code,
          is_primary_party: key.is_primary_party,
          start_date: key.start_date,
          end_date: key.end_date,
          sub_unit_party_id: key.sub_unit_party_id
        };
      });
      const payload = {
        fmsSubUnitId: this.fmsSubUnitRowDtl.fms_sub_unit_id,
        subUnitCustomer: {
          customer_list: details ? details : null
        }
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/addEditFmsSubUnitCustomer', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.editMode = false;
            this.responseMsg = response.data.message;
            this.subUnitCustomerData = response.data.data;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    startDateIndex(index) {
      this.specIndex = index;
      this.subUnitCustomerData[index].end_date = null;
    },
    endDateIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.subUnitCustomerData[this.specIndex].start_date
      );
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PARTY_TYPE) {
        this.subUnitCustomerData[this.customerIndex].customer_type_code =
          item.value_code;
        this.subUnitCustomerData[this.customerIndex].customer_type_meaning =
          item.value_meaning;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.customerIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHidePartyModal(flag, index) {
      this.showPartyModal = flag;
      this.gridIndex = index;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    showHideCustomerSiteModal(flag) {
      this.showCustomerSiteModal = flag;
    },
    getCustomerSiteByPartyId(index) {
      this.gridIndex = index;
      const payload = {
        customerId: this.subUnitCustomerData[index].customer_id
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
          this.showCustomerSiteModal = true;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSelectedPartyCustomer(item) {
      this.subUnitCustomerData[this.gridIndex].party_name = item.party_name;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.subUnitCustomerData[this.gridIndex].customer_id =
              response.data.data.customer_id;
          }
        });
    },
    selectedSite(item) {
      this.subUnitCustomerData[this.gridIndex].customer_site_name =
        item.site_name;
      this.subUnitCustomerData[this.gridIndex].customer_site_id =
        item.customer_site_id;
      this.subUnitCustomerData[this.gridIndex].bill_to_address =
        item.bill_to_address;
      this.subUnitCustomerData[this.gridIndex].address_id =
        item.bill_to_address_id;

      this.showCustomerSiteModal = false;
    },
    setCustomerData(item) {
      let subUnitPartyRow = this.subUnitCustomerData[this.customerIndex];
      subUnitPartyRow.customer_id = item.customerId;
      subUnitPartyRow.party_name = this.$store.state.party.partyName;
      subUnitPartyRow.bill_to_address = item.customerData.bill_to_address;
      subUnitPartyRow.address_id = item.customerData.bill_to_address_id;
      subUnitPartyRow.customer_site_id = item.customerData.customer_site_id;
      subUnitPartyRow.customer_site_name = item.customerData.site_name;
      this.showCustModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.subUnitCustomerData.push({
          customer_type_meaning: null,
          customer_type_code: null,
          customer_site_name: null,
          start_date: null,
          end_date: null,
          start_date_end_date: null,
          active: true,
          customer_site_id: 0,
          allotment_number: null,
          sub_unit_party_id: 0,
          is_primary_party: true,
          address_id: 0,
          bill_to_address: null,
          customer_id: 0,
          party_name: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.subUnitCustomerData.splice(index, 1);
      }
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'FMS_PARTY_TYPE') {
        this.subUnitCustomerData[index].customer_type_code = null;
        this.subUnitCustomerData[index].customer_type_meaning = null;
      } else if (vsetCode === 'CUSTOMER_NAME') {
        this.subUnitCustomerData[index].party_name = null;
        this.subUnitCustomerData[index].customer_id = null;
        this.subUnitCustomerData[index].customer_site_name = null;
        this.subUnitCustomerData[index].customer_site_id = null;
        this.subUnitCustomerData[index].bill_to_address = null;
        this.subUnitCustomerData[index].bill_to_address_id = null;
      } else if (vsetCode === 'CUSTOMER_SITE') {
        this.subUnitCustomerData[index].customer_site_name = null;
        this.subUnitCustomerData[index].customer_site_id = null;
        this.subUnitCustomerData[index].bill_to_address = null;
        this.subUnitCustomerData[index].bill_to_address_id = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
    clearTimeout(this.setCustCode);
  },
  destroyed() {
    this.eventBus.$off('setCustomerData');
  }
};
