import commonHelper from '@/app/utility/common.helper.utility';
import FmsUnitDetails from './unitDetails';
import FmsProject from '../fmsProject';
import FmsTower from '../fmsTower';
import FmsFloor from '../fmsFloor';
import UnitInfo from './unitInfo';
import SubUnitDetail from '../fmsSubunit/subUnitInfo/';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'FmsUnit',
  components: {
    FmsUnitDetails,
    FmsProject,
    FmsTower,
    FmsFloor,
    SubUnitDetail,
    UnitInfo
  },
  watch: {
    currentPage: function() {
      this.getFmsUnitGridList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsUnitGridList();
    }
  },
  props: [
    'projecName',
    'projectId',
    'towerName',
    'towerId',
    'floorName',
    'floorId',
    'showFmsUnitComponent',
    'showFMSModalUnit',
    'selectedProject'
  ],
  data() {
    return {
      unsubscribe: null,
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: [],
      showFmsUnitDetails: false,
      showFmsComponent: false,
      showSubUnitDetail: false,
      showDetails: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      editMode: false,
      updateMode: false,
      tab: null,
      fmsProj: {
        value: null,
        text: null
      },
      fmsTower: {
        value: null,
        text: null
      },
      fmsFloor: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      fmsUnitName: null,
      fmsUnitDisplay: null,
      fmsUnitId: null,
      subUnitId: null,
      fmsUnitData: [],
      isSubunitLeaseable: false,
      isOutrightUnit: false,
      fmsUnitRowDtl: null,
      fmsUnitFields: [
        {
          key: 'sub_unit_flag',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'is_leaseable_unit'
        },
        {
          key: 'is_outright_unit'
        },
        {
          key: 'is_active'
        },
        {
          key:'is_possessionable_unit',
          label:' Is Possessionable Unit'
        },
        {
          key:'is_shown_on_portal',
          class:'text-center'

        },
        {
          key: 'unit_name',
          label: 'Unit'
        },
        {
          key: 'unit_display_name',
          label: 'Unit Display'
        },
        {
          key: 'fms_master_project_name',
          label: 'Master Project'
        },
        {
          key: 'fms_sector_name',
          label: 'Sector'
        },
        {
          key: 'fms_pocket_name',
          label: 'Pocket'
        },
        {
          key: 'fms_project_name',
          label: 'Project'
        },
        {
          key: 'fms_tower_name',
          label: 'Tower'
        },
        {
          key: 'floor_name',
          label: 'Floor'
        },
        {
          key: 'crm_project_name',
          label: 'CRM Project'
        },
        {
          key: 'crm_tower_name',
          label: 'CRM Tower'
        },
        {
          key: 'crm_floor_name',
          label: 'CRM Floor'
        },
        {
          key: 'crm_unit_name',
          label: 'CRM Unit'
        },
        {
          key: 'unit_category_meaning',
          label: 'Unit Category'
        },
        {
          key: 'unit_sub_category_meaning',
          label: 'Unit SubCategory'
        },
        {
          key: 'version'
        },
        {
          key: 'bill_commence_date'
        },
        {
          key: 'possession_date'
        },
        {
          key: 'phy_possession_date'
        },
        {
          key: 'offer_possession_date',
          label: 'Off. Possession Date'
        },
        {
          key: 'agreement_date'
        },
        {
          key: 'registry_date'
        },
        {
          key: 'fms_unit_display_name',
          label: 'Unit Display Name -FMS'
        },
       
        {
          key:'leasable_reason_vset_meaning',
          label: ' Not Leasable Unit Reason'
        }

      ],
      routeName: this.$router.currentRoute.name,
      isPossessionable:null,
      isShownOnPortal:false

    };
  },
  validations: {
    fmsProj: {
      text: {
        required
      }
    }
  },
  mounted() {
    if (this.showFmsUnitComponent || this.showFMSModalUnit) {
      this.fmsProj.text = this.projecName;
      this.fmsTower.text = this.towerName;
      this.fmsFloor.text = this.floorName;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          if (this.showDetails) {
            return null;
          }
          this.showFmsUnitDetails = true;
          this.fmsUnitRowDtl = null;
        }
        if (
          actionName === 'download' &&
          !this.showDetails &&
          !this.showFmsUnitDetails
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'unitInventory/getFmsUnitGridList',
            'Fms-Unit',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getFmsUnitGridList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          fms_project_id: this.projectId ? this.projectId : this.fmsProj.value,
          fms_tower_id: this.towerId ? this.towerId : this.fmsTower.value,
          fms_floor_id: this.floorId ? this.floorId : this.fmsFloor.value,
          unit_name: this.fmsUnitName,
          unit_display_name: this.fmsUnitDisplay,
          is_leaseable_unit: this.isSubunitLeaseable,
          is_outright_unit: this.isOutrightUnit,
          is_possessionable_unit:this.isPossessionable,
          is_shown_on_portal:this.isShownOnPortal
          };
        this.loader = true;
        this.$store
          .dispatch('unitInventory/getFmsUnitGridList', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.fmsUnitData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'FmsUnitDetails') {
        return (this.childName = 'FMS Unit Details');
      } else if (tab === 'FmsProject') {
        return (this.childName = 'FMS Project');
      } else if (tab === 'FmsTower') {
        return (this.childName = 'FMS Tower');
      } else if (tab === 'FmsFloor') {
        return (this.childName = 'FMS Floor');
      }
    },
    createSubUnit() {
      this.loader = true;
      this.responseMsg = null;
      const selectedData = this.fmsUnitData.filter(data => data.sub_unit_flag);
      let selectedSubUnits = selectedData.length;
      if (selectedSubUnits === 0) {
        alert('Please select at lease one Unit');
        return (this.loader = false);
      } else if (selectedSubUnits === 1) {
        this.$store
          .dispatch(
            'unitInventory/createSubunitFromUnitId',
            selectedData[0].fms_unit_id
          )
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.subUnitId = response.data.data;
              this.getSubUnitDetailsById();
            }
          })
          .catch(() => {
            this.loader = false;
            alert(appStrings.autoFailedMsg);
          });
      } else {
        this.createMultipleSubunitFunc(selectedData, selectedSubUnits);
      }
      this.getFmsUnitGridList();
    },
    async createMultipleSubunitFunc(selectedData, selectedSubUnits) {
      var subUnitCount = [];
      for (let i = 0; i < selectedSubUnits; i++) {
        const response = await this.$store.dispatch(
          'unitInventory/createSubunitFromUnitId',
          selectedData[i].fms_unit_id
        );
        this.loader = false;
        if (response.status === 200) {
          this.subUnitId = response.data.data;
          subUnitCount.push(this.subUnitId);
        }
      }
      if (subUnitCount.length > 0) {
        alert(`${subUnitCount.length} SubUnit created`);
      }
    },
    getSubUnitDetailsById() {
      const subUnitId = this.subUnitId;
      this.loader = false;
      this.$store
        .dispatch('unitInventory/getSubUnitDetailsById', subUnitId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.fmsSubUnitRowDtl = response.data.data;
            this.$store.dispatch(
              'shared/setDataForFmsSubunit',
              this.fmsSubUnitRowDtl
            );
            this.openCloseSubUnitModal(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openCloseSubUnitModal(flag) {
      this.showSubUnitDetail = flag;
    },
    hideFmsComponent(flag, component) {
      this.showFmsComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    hideFmsUnitDetails() {
      this.showFmsUnitDetails = false;
    },
    rowSelected(rowData) {
      if (this.showFMSModalUnit || this.showFmsUnitComponent) {
        this.$emit('selectedFmsUnit', rowData);
      } else {
        this.fmsUnitRowDtl = rowData;
        this.$store.dispatch('shared/setDataForFmsUnit', rowData);
        this.showDetails = true;
      }
    },
    selectedFmsProj(item) {
      this.showFmsComponent = false;
      this.fmsProj = {
        value: item.fms_prj_id,
        text: item.fms_prj_name
      };
      this.fmsTower = this.defaultValue;
      this.fmsFloor = this.defaultValue;
    },
    selectedFmsTower(item) {
      this.showFmsComponent = false;
      this.fmsTower = {
        value: item.fms_tower_id,
        text: item.fms_tower_name
      };
      this.fmsFloor = this.defaultValue;
    },
    selectedFmsFloor(item) {
      this.showFmsComponent = false;
      this.fmsFloor = {
        value: item.fms_floor_id,
        text: item.floor_name
      };
    },
    closeUnitInfo() {
      this.showDetails = false;
      this.getFmsUnitGridList();
    },
    closeCreatedSubunit() {
      this.openCloseSubUnitModal(false);
      this.$store.dispatch('shared/setDataForFmsSubunit', null);
    },
    clearFilters() {
      this.fmsProj = this.defaultValue;
      this.fmsTower = this.defaultValue;
      this.fmsFloor = this.defaultValue;
      this.fmsUnitName = null;
      this.fmsUnitDisplay = null;
      this.fmsUnitId = null;
      this.fmsUnitData = [];
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.fmsProj.value) {
        this.fmsProj = this.defaultValue;
        this.fmsTower = this.defaultValue;
        this.fmsFloor = this.defaultValue;
      } else if (vsetCode === this.fmsTower.value) {
        this.fmsTower = this.defaultValue;
        this.fmsFloor = this.defaultValue;
      } else if (vsetCode === this.fmsFloor.value) {
        this.fmsFloor = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch('shared/setDataForFmsUnit', null);
  }
};
