import fmsUnitDetails from '../unitDetails';
import originalUnitArea from '../unitInfo/unitOriginalArea';
import unitOwnership from './unitOwnership';
import store from '../../../../../../store';
export default {
  name: 'UnitInfo',
  components: {
    fmsUnitDetails,
    originalUnitArea,
    unitOwnership
  },
  data() {
    return {
      loader: false,
      actionName: null,
      activeTab: 'UnitDetails',
      revisedArea: false
    };
  },
  created() {
    this.fmsUnitRowDtl = store.state.shared.fmsUnitRecord;
  },
  methods: {
    selectedTab(tabValue) {
      this.activeTab = tabValue;
      if(this.activeTab === 'RevisedArea') {
          this.revisedArea = true;
      } else {
          this.revisedArea = false;
      }
    },
    closeTabs() {
      this.$emit('closeUnitInfo');
    },
    buttonAccessPermissions(actionName) {
      this.actionName = actionName;
    }
  },
  beforeDestroy() {
    this.$store.dispatch('shared/setDataForFmsUnit', null);
  }
};
