import commonHelper from '@/app/utility/common.helper.utility';
import FmsSubUnitDetails from './subunitDetails';
import FmsProject from '../fmsProject';
import FmsTower from '../fmsTower';
import FmsFloor from '../fmsFloor';
import FmsUnit from '../fmsUnit';
import SubUnitInfo from './subUnitInfo';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'FmsSubUnit',
  components: {
    FmsSubUnitDetails,
    FmsProject,
    FmsTower,
    FmsFloor,
    FmsUnit,
    SubUnitInfo
  },
  watch: {
    currentPage: function() {
      this.getFmsSubUnitGridList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsSubUnitGridList();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  props: [
    'fmsProjectName',
    'fmsProjectId',
    'showFmsSubUnitModal',
    'subUnitForLease',
    'showFmsUnitModal'
  ],
  data() {
    return {
      unsubscribe: null,
      showDetails: false,
      loader: false,
      showFmsSubUnitDetails: false,
      showFmsComponent: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      editMode: false,
      updateMode: false,
      tab: null,
      fmsProj: {
        value: null,
        text: null
      },
      fmsTower: {
        value: null,
        text: null
      },
      fmsFloor: {
        value: null,
        text: null
      },
      fmsUnit: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      fmsSubunit: null,
      isSubunitLeaseable: false,
      active: false,
      defaultFlag: false,
      dummy: false,
      freeze: false,
      fmsSubUnitRowDtl: null,
      checkboxes: false,
      selectAllCheckBox: false,
      showSelectButton: false,
      fmsSubunitData: [],
      fmsSubunitFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'fms_unit_name',
          label: 'Unit'
        },
        {
          key: 'fms_sub_unit_name',
          label: 'Sub Unit'
        },
        {
          key: 'billable_area'
        },
        {
          key: 'billable_area_uom_vset_meaning',
          label: 'Billable Area UOM'
        },
        {
          key: 'fms_project_name',
          label: 'Project'
        },
        {
          key: 'fms_tower_name',
          label: 'Tower'
        },
        {
          key: 'fms_floor_name',
          label: 'Floor'
        },
        {
          key: 'active'
        },
        {
          key: 'default_flag',
          label: 'Default'
        },
        {
          key: 'dummy'
        },
        {
          key: 'freeze_flag',
          label: 'Freeze'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      calculateArea: 0,
      routeName: this.$router.currentRoute.name,
    };
  },
  validations: {
    fmsProj: {
      text: {
        required
      }
    }
  },
  mounted() {
    if (this.showFmsSubUnitModal) {
      this.fmsProj.text = this.fmsProjectName;
      this.fmsProj.value = this.fmsProjectId;
      this.isSubunitLeaseable = this.subUnitForLease;
      this.showSelectButton = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          if (this.showDetails) {
            return null;
          }
          this.showFmsSubUnitDetails = true;
          this.fmsSubUnitRowDtl = null;
        }
        if (
          actionName === 'download' &&
          !this.showDetails &&
          !this.showFmsSubUnitDetails
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'unitInventory/getFmsSubUnitGridList',
            'Fms-Sub-Unit',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    calculateBillingArea() {
      let sum = 0;
      this.fmsSubunitData.forEach(element => {
        if(element.select){
          sum+=parseFloat(element.billable_area)
        }
        this.calculateArea = parseFloat(sum).toFixed(2);
      });
    },
    getFmsSubUnitGridList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          fms_project_id: this.showFmsSubUnitModal
            ? this.fmsProjectId
            : this.fmsProj.value,
          fms_tower_id: this.fmsTower.value,
          fms_floor_id: this.fmsFloor.value,
          fms_unit_id: this.fmsUnit.value,
          sub_unit_name: this.fmsSubunit,
          is_leaseable_unit: this.isSubunitLeaseable
        };
        this.loader = true;
        this.$store
          .dispatch('unitInventory/getFmsSubUnitGridList', this.payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.fmsSubunitData = resp.data.data.page;
              if (
                resp.data.data.page &&
                resp.data.data.page.length &&
                this.showSelectButton
              ) {
                this.fmsSubunitData = this.fmsSubunitData.map(data => {
                  data.select = false;
                  return data;
                });
              }
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'FmsSubUnitDetails') {
        return (this.childName = 'FMS SubUnit Details');
      } else if (tab === 'FmsProject') {
        return (this.childName = 'FMS Project');
      } else if (tab === 'FmsTower') {
        return (this.childName = 'FMS Tower');
      } else if (tab === 'FmsFloor') {
        return (this.childName = 'FMS Floor');
      } else if (tab === 'FmsUnit') {
        return (this.childName = 'FMS Unit');
      }
    },
    hideFmsComponent(flag, name) {
      this.showFmsComponent = flag;
      this.tab = name;
      this.setChildName(this.tab);
    },
    hideFmsSubUnitDetails() {
      this.showFmsSubUnitDetails = false;
    },
    rowSelected(rowData) {
      if (!this.showFmsSubUnitModal) {
        this.fmsSubUnitRowDtl = rowData;
        this.$store.dispatch('shared/setDataForFmsSubunit', rowData);
        this.showDetails = true;
      }
      if (this.showFmsUnitModal) {
        this.$emit('selectedFmsSubUnit', rowData);
      }
    },
    closeUnitInfo() {
      this.showDetails = false;
      this.getFmsSubUnitGridList();
    },
    selectedFmsProj(item) {
      this.showFmsComponent = false;
      this.fmsProj = {
        value: item.fms_prj_id,
        text: item.fms_prj_name
      };
    },
    selectedFmsTower(item) {
      this.showFmsComponent = false;
      this.fmsTower = {
        value: item.fms_tower_id,
        text: item.fms_tower_name
      };
    },
    selectedFmsFloor(item) {
      this.showFmsComponent = false;
      this.fmsFloor = {
        value: item.fms_floor_id,
        text: item.floor_name
      };
    },
    selectedFmsUnit(item) {
      this.showFmsComponent = false;
      this.fmsUnit = {
        value: item.fms_unit_id,
        text: item.unit_name
      };
    },
    clearFilters() {
      this.fmsProj = this.defaultValue;
      this.fmsTower = this.defaultValue;
      this.fmsFloor = this.defaultValue;
      this.fmsUnit = this.defaultValue;
      this.fmsSubunit = null;
      this.fmsSubunitData = [];
    },
    checkUncheckAll() {
      this.fmsSubunitData = this.fmsSubunitData.map(data => {
        data.select = this.selectAllCheckBox;
        return data;
      });
      this.calculateBillingArea()
    },
    selectBoxChecked(flag, index) {
      this.fmsSubunitData[index].select = flag;
      this.calculateBillingArea()
    },
    mapSubUnits() {
      const assignSubUnitData = this.fmsSubunitData.filter(key => key.select);
      this.$emit('mapSubUnitForLease', assignSubUnitData);
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.fmsProj.value) {
        this.fmsProj = this.defaultValue;
        this.fmsTower = this.defaultValue;
        this.fmsFloor = this.defaultValue;
        this.fmsUnit = this.defaultValue;
      } else if (vsetCode === this.fmsTower.value) {
        this.fmsTower = this.defaultValue;
        this.fmsFloor = this.defaultValue;
        this.fmsUnit = this.defaultValue;
      } else if (vsetCode === this.fmsFloor.value) {
        this.fmsFloor = this.defaultValue;
        this.fmsUnit = this.defaultValue;
      } else if (vsetCode === this.fmsUnit.value) {
        this.fmsUnit = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch('shared/setDataForFmsSubunit', null);
    this.unsubscribe();
  }
};
